.add-conv-flex{
    width: 100%;
    display: flex;
    justify-content: center;
    margin-bottom: 25px;
    margin-top: 20px;
}
.add-conv {
    width: 90%;
    height: 45px;
    font-family: 'Roboto', sans-serif;
    font-size: 11px;
    text-transform: uppercase;
    letter-spacing: 2.5px;
    font-weight: 500;
    color: #000;
    background-color: #fff;
    border: none;
    border-radius: 45px;
    box-shadow: 0px 8px 15px rgba(0, 0, 0, 0.1);
    transition: all 0.3s ease 0s;
    cursor: pointer;
    outline: none;
    display: flex;
    align-items: center;
    justify-content: center;
    }
  
  .add-conv:hover {
    background-color: #2DC0DA;
    box-shadow: 0px 15px 20px rgba(46, 229, 157, 0.4);
    color: #fff;
  }
.conv-people{
    width: 90%;
    height: 68vh;
    padding: 13px;
    background: #FFFFFF;
    border-radius: 5px;
}
.conv-people hr{
    margin-top: 5px;
    margin-bottom: 14px;
}
.msg-box{
    padding: 13px 30px;
    width: 100%;
    height: 80vh;
    background: #FFFFFF;
    margin-top: 20px;
    border: 1px solid #DBDBDB;
    box-shadow: 0 2px 8px 0 rgb(0 0 0 / 20%), 0 2px 8px 0 rgb(0 0 0 / 19%);
}
.msg-name-flex{
    display: flex;
    align-items: center;
}
.msg-name-flex img{
    width: 50px;
    height: 50px;
    object-fit: cover;
    border-radius: 3px;
    margin-right: 10px;
    border-radius: 50%;
}
.msg-name-flex span{
    font-size: 18px;
    font-weight: 500;
}
.msg-display small{
    color: #D7D7D7;
    font-weight: 300;
}
.msg-display-text span{
    margin: 0;
    background: #D9E6FF;
    padding: 7px 10px;
    border-radius: 4px;
    font-weight: 400;
}
.msg-display-text{
    margin-top: 15px;
    margin-bottom: 4px;
}
.msg-display{
    height: 65%;
}
.msg-input-fuild-flex{
    display: flex;
    align-items: center;
    justify-content: space-between;
    border: 1px solid #DBDBDB;
    padding: 6px;
    background: #E0E0E0;
    border-radius: 6px;
}
.msg-input-fuild-flex input{
    border: none;
    outline: none;
    width: 86%;
    background: transparent;
}
.mes-container{
    width: 100%;
    height: 100%;
    overflow-x: hidden;
}
.inNotConv{
    height: 65%;
}
.inNotConv h2{
    text-align: center;
    font-size: 22px;
    font-weight: 300;
}
.inNotConv h6{
    text-align: center;
    font-size: 14px;
    font-weight: 300;
    color: #8E8E8E;
}
.msg-position-left{
    display: flex;
    justify-content: left;
}
.msg-position-right{
    display: flex;
    justify-content: right;
}
.own-class{
    display: flex;
    justify-content: end;
}
.msg-name-flex svg{
    width: 35px;
    height: 35px;
    margin-right: 16px;
}