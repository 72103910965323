.conv-name-flex{
    display: flex;
    align-items: center;
}
.conv-name-flex img{
    width: 40px;
    height: 40px;
    border-radius: 3px;
    margin-right: 6px;
    object-fit: cover;
}
.conv-name-flex h5{
    font-size: 17px;
    font-weight: 500;
    margin: 0;
}