@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@100;300;400;500;700;900&display=swap');
*{
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: 'Roboto', sans-serif;
}
a{
  text-decoration: none !important;
}
.header-page{
  position: relative;
  width: 100%;
}
.circle-button{
  width: 25px !important;
  height: 25px !important;
}
.google-button{
  text-transform: none !important;
  width: 100% !important;
  border-radius: 40px !important;
  display: flex !important;
  justify-content: center !important;
}
.googleLogOut div{
display: none !important;
}
.googleLogOut{
  width: 18% !important;
 height: 24px !important;
}
.googleLogOut-2 button{
  display: none !important;
}
.googleLogOut span{
  border: none !important;
  width: 100% !important;
  height: 24px !important;
  margin: 0 !important;
  padding: 0 !important;
}


@media (max-width: 575.98px) { 
  .search-bar{
    display: none;
  }
  .logo-f-r{
    width: 90px;
  }
  .header-img-f-r{
    margin: 0 !important;
  }
  .home-5-f-r{
    display: none;
  }
  .post-f-r{
    width: 100% !important;
  }
  .comment-box-div-width {
    width: 78% !important;
  }
  .profile-logo-f-r{
    width: 90px;
  }
  .signup-logo {
    margin-top: 108px !important;
    margin-bottom: 15px !important;
}
.signup-logo-f-r{
  margin-top: 50px !important;
}
  .business-name-box{
    width: 100% !important;
    margin-top: 70px !important;
  }
  .category-box{
    width: 100% !important;
    margin-top: 78px !important;
  }
  .tags-box{
    width: 100% !important;
    margin-top: 60px !important;
  }
  .uploadBusiness-info-box-flex{
    width: 100% !important;
  }
  .uploadBusiness-box{
    width: 100% !important;
    margin-top: 60px !important;
  }
  .select-cover-photo-box{
    width: 100% !important;
    margin-top: 95px !important;
    margin-left: 12px !important;
    margin-right: 12px !important;
  }
  .captureLogo svg{
    color: black !important;
  }
  .captureImg{
    width: 100% !important;
    margin-top: 0 !important;
    border-radius: 5px !important;
  }
  .img-after-take{
    margin-top: 27px !important;
  }
  .profile-l-f-r{
    display: none !important;
  }
  .first-col{
    margin-right: 0 !important;
  }
  .profile-img-flex img{
    width: 120px !important;
    height: 120px !important;
  }
  .second-col{
    width: 100% !important;
  }
  .second-col-f-r{
    width: 100% !important;
  }
  .profile-name-flex{
    align-items: center !important;
  }
  .profile-name-flex h4{
    font-size: 16px !important;
    margin-right: 0px !important;
  }
  .profile-name-flex span{
    padding: 3px 4px !important;
    margin-right: 0 !important;
    font-size: 11px !important;
  }
  .profile-f-s-r{
    display: block !important;
    text-align: center !important;
  }
  .timeline-img{
    height: 125px !important;
  }
  .timeline-img img{
    height: 89% !important;
  }
  .for-timeline-img-r{
    padding-left: 4px !important;
    padding-right: 4px !important;
  }
  .follow-box{
    width: 100% !important;
  }

  .conv-name-flex{
    justify-content: start !important;
  }
  .add-conv{
    border-radius: 4px !important;
    width: 100% !important;
    padding: 0 10px !important;
  }
  .msg-box{
    height: 80vh !important;
  }
  .msg-display{
    height: 68% !important;
  }
  .add-icon-f-r{
    display: block !important;
  }
  .add-icon-f-r-2{
    color: rgb(90, 90, 90) !important;
    font-size: 29px !important;
  }

  .create-post{
    margin-top: 60px !important;
  }
  .filter-section div h6{
    margin-top: 10px !important;
  }
  .filter-second-section-flex{
    display: none !important;
  }
  .create-post-btn{
    margin-left: 0 !important;
    text-align: center !important;
  }
  .photoShare-second-flex img{
    width: 75px !important;
    height: 75px !important;
  }
  .photoShare-main-flex div textarea{
    height: 82px !important;
  }
  .photoShare-main-flex div textarea{
    margin-top: 14px !important;
    margin-left: 10px !important;
  }
  .photoShare-first-flex img{
    width: 55px !important;
    height: 55px !important;
  }
  .photoShare-main-flex div textarea {
    width: 200px !important;
  }
  .home-photo-icon-flex svg:nth-child(4){
    margin-left: 62% !important;
  }
  .second-col-f-r span{
    font-size: 12px !important;
  }
  .images-footer-single-flex img{
    width:15px !important;
    height: 15px !important;
  }
  .images-footer-single-flex{
    margin-right: 0 !important;
  }
  .imagefooter-small{
    display: none !important;
  }
  .edit-left-side h4{
    margin-left: 10px !important;
    font-size: 13px !important;
  }
  .edit-name-main{
    margin-left: 5px !important;
  }
  .edit-name-flex-2{
    width: 80px;
  }
  .edit-name-flex-2 h4{
    font-size: 13px !important;
  }
  .edit-name-username input{
    width: 180px !important;
    font-size: 14px !important;
  }
  .edit-name-btn button{
    font-size: 10px;
  }
  .edit-name-btn-password div button{
    font-size: 10px !important;
  }
  .edit-name-flex-2{
    width: 80px !important;
  }
  .bio-info{
    display: none !important;
  }
  .post-f-m{
    font-size: 13px !important;
  }
  .profile-name-flex a{
    display: none;
  }
  .profile-name-flex svg{
    display: none;
  }
  .profile-name-flex-2{
    display: block !important;
  }
  .follow-flex div{
    margin-right: 20px !important;
  }
  .create-post img{
    width: 150px !important;
    height: 150px !important;
  }
  .create-post-h1{
    font-size: 24px !important;
    margin: 10px 0 !important;
  }
  .filter-btn-f-m{
    margin-top: 20px !important;
  }
  .create-post-btn button{
    margin-top: 200px !important;
  }
  .create-post-btn-4{
    text-align: center !important;
  }
  .create-post-btn-4 button{
    margin-top: 170px !important;
  }
  .m-f-r{
    display: none !important;
  }
  .c-f-r{
    display: none !important;
  }
  .c-p-f-r{
    display: none !important;
  }
  .follow-box-f-r{
  margin: 0 !important;
  }
  .m-f-n-r{
    display: none !important;
  }
 
}


@media (max-width: 391px) { 
  .profile-img-flex img{
    width: 100px !important;
    height: 100px !important;
  }
  .home-photo-icon-flex svg:nth-child(4){
    margin-left: 56% !important;
  }
}