.header-page{
    width: 100%;
    background: #FFFFFF;
}
.header-row{
    height: 64px;
    box-shadow: 0 2px 5px 0 rgba(0,0,0,0.16);
}
.header-col-7-flex{
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    height: 100%;
}
.header-col-5-flex{
    display: flex;
    align-items: center;
    justify-content: space-around;
    height: 100%;
}
.search-bar{
    border: 1px solid #E4E4E4;
    padding: 2px 10px;
    border-radius: 4px;
    background: #fafafa;
}
.search-bar input{
    border: none;
    outline: none;
    margin-left: 6px;
    background: transparent;
}
.typography{
    display: none;
  }
  .close-icon{
    margin-left: 90% !important;
    cursor: pointer;
  }
  .shareImgContainer{
    padding: 0 20px 10px 20px;
    position: relative;
  }
  .shareImgContainer input{
    border: none;
    outline: none;
    margin-bottom: 15px;
    padding-top: 20px;
  }
  .shareCancelImg{
    position: absolute;
    top: 0;
    right: 20px;
    cursor: pointer;
    opacity: 0.7;
  }
  .shareImg{
    width:  100%;
    object-fit: cover;
  }  
  .shareButton{
    border: none;
    width: 65px;
    height: 35px;
    border-radius: 5px;
    background-color: green;
    font-weight: 500;
    margin-right: 20px;
    cursor: pointer;
    color: white;
    position: absolute;
    top: 17px;
    right: 0;
    display: flex;
    align-items: center;
    justify-content: center;
  } 

  .upload-logo svg{
    margin-left: 35%;
}
.upload-logo h1{
color: rgba(38,38,38,1);
font-weight: 300;
font-size: 22px;
text-align: center;
margin: 20px 0 !important;
}
.upload-logo input{
display: none;
}
.upload-logo button{
border: none;
margin-left: 90px;
background-color: rgba(0,149,246,1);
border-radius: 4px;
color: rgba(255,255,255,1);
font-size: 15px;
padding: 3px 5px;
}
.upload-logo h3{
font-size: 16px;
font-weight: 600;
text-align: center;
color: #262626;
}
.upload-logo hr{
margin-bottom: 80px;
} 
.add-icon-f-r{
  display: none;
}   
