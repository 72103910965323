.user-name-flex{
    display: flex;
    align-items: center;
    justify-content: start;
    padding: 10px;
}
.user-name-box{
    display: flex;
    align-items: center;
    justify-content: start;
}
.user-photo img{
    width: 45px;
    height: 45px;
    object-fit: cover;
    border-radius: 50%;
}
.user-photo{
    margin-right: 12px;
}
.user-name-location h3{
    margin: 0;
    font-weight: 500;
    font-size: 14px;
    color: #000000;
    letter-spacing: 0.7px;
}
.user-name-location p{
    margin: 0;
    font-weight: 400;
    font-size: 12px;
    color: #000000;
    letter-spacing: 0.7px;
}
.home-photo-flex{
    width: 100%;
    height: 470px;
    background-color: #000000;
}
.home-photo-flex img{
    width: 100%;
    height: 100%;
    object-fit: cover;
}
.swiper-button-next,
.swiper-button-prev{
    color: white !important;
    
}
.swiper-button-next:after,
.swiper-button-prev:after{
    font-size: 25px !important;
}
.swiper-pointer-events{
    height: 100% !important;
}
.home-photo-icon-flex{
    display: flex;
    height: 60px;
    align-items: center;
}
.home-photo-icon-flex svg:nth-child(1){
    margin-left: 14px;
}
.home-photo-icon-flex svg:nth-child(2){
    margin-left: 16px;
}
.home-photo-icon-flex svg:nth-child(3){
    margin-left: 16px;
}
.home-photo-icon-flex svg:nth-child(4){
    margin-left: 68.5%;
   
}
.comment-box-flex{
    display: flex;
    align-items: center;
    height: 50px;
    margin: 0;
    padding: 0;
    border-top: 1px solid #DBDBDB;
}
.comment-box-input{
    padding: 8px;
    border: none;
    outline: none;
    width: 100%;
}
.comment-box-div-width{
    width: 85%;
}
.comment-box-input::placeholder{
    font-size: 14px;
}
.postButton{
    color: #B4E0FC;
    font-size: 13px;
    cursor: pointer;
}
.postButton2{
    color: #0F9BF6;
    font-size: 13px;
    cursor: pointer;
    font-weight: 500;
}
.show-comment{
    padding: 0px 12px;
}