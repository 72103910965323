.profile-img-flex{
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1.09px dotted #fa709a;
    border-radius: 100%;
    display: inline-block;
}
.profile-img-flex img{
    width: 150px;
    height: 150px;
    border-radius: 50%;
    object-fit: cover;
    border: 3px solid #fff;
}
.profile-name-flex{
    display: flex;
    align-items: center;
    justify-content: start;
}
.profile-name-flex-2{
    display: flex;
    align-items: center;
    justify-content: start;
    margin-top: 20px;
    display: none;
}
.follow-flex{
    display: flex;
    margin-top: 20px;
}
.profile-name-flex h4{
    color: #5A5A5A;
    font-size: 25px;
    font-weight: 400;
    margin-right: 11px;
    margin-bottom: 0;
    letter-spacing: 1.5px;
}
.profile-name-flex a span{
    border: 1px solid #DBDBDB;
    font-size: 14px;
    padding: 3px 10px;
    border-radius: 4px;
    font-weight: 500;
    cursor: pointer;
    margin-right: 14px;
    color: black;
}
.profile-name-flex-2 a span{
    border: 1px solid #DBDBDB;
    font-size: 14px;
    padding: 3px 10px;
    border-radius: 4px;
    font-weight: 500;
    cursor: pointer;
    margin-right: 14px;
    color: black;
}
.logoutIcon{
    font-size: 30px !important;
    padding: 2px;
    border: 1px solid #DBDBDB;
    border-radius: 3px;
    cursor: pointer;
}
.second-col{
    display: flex;
    margin-top: 40px;
}
.first-col{
    display: flex;
    align-items: center;
    justify-content: end;
    margin-right: 40px;
    margin-top: 40px;
}
.desc-flex{
    display: flex;
    align-items: center;
    margin-bottom: 25px;
}
.desc-flex div{
    border: 1px solid #CDCDCD;
    padding: 0px 5px;
    width: 205px;
    border-radius: 4px;   
}
.desc-flex span{
    margin-right: 10px;
    font-weight: 500;
    width: 125px;
}
.desc{
    width: 330px;
    margin-top: 12px;
    font-size: 14px;
}
.timeline-img img{
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 15px;
}
.timeline-img{
    width: 100%;
    height: 280px;
}
.profile-modal-textare{
    resize: none;
    padding: 4px;
    border-radius: 8px;
    padding: 15px;
    width: 100%;
}
.bio-info{
    margin-top: 16px;
}
.bio-info h5{
    font-size: 16px;
    font-weight: 500;
    letter-spacing: .8px;
    margin-top: 5px !important;
    margin-bottom: 0 !important;
}
.bio-info h6{
    font-size: 16px;
    color: #262626;
    font-weight: 400;
    letter-spacing: .8px;
    margin-top: 5px !important;
    margin-bottom: 0 !important;
}
.bio-info a{
    font-size: 16px;
    font-weight: 500;
    color: #00376B;
    letter-spacing: .8px;
    margin-top: 5px !important;
    margin-bottom: 0 !important;
}
.css-1wnsr1i{
    width: 250px !important;
    border-radius: 8px !important;
}
.modal-box h5{
    font-size: 16px;
    cursor: pointer;
    padding: 15px 0;
    margin: 0 !important;
    color: black !important;
}
.modal-box a h5{
    font-size: 16px;
    cursor: pointer;
    padding: 15px 0;
    margin: 0 !important;
    color: black !important;
}
.modal-box a h5:hover{
    background-color: rgba(250, 250, 250);
}
.modal-box h5:hover{
    background-color: rgba(250, 250, 250);
}
.modal-box{
    text-align: center;
}
.modal-box hr{
    margin: 0 !important;
}
.modal-box button{
    color: black !important;
    font-size: 16px !important;
    width: 100% !important;
    padding: 23px 0 !important;
    box-shadow: rgb(0 0 0 / 24%) 0px 0px 0px 0px, rgb(0 0 0 / 24%) 0px 0px 0px 0px !important;
}
.modal-box button:hover{
    background-color: rgba(250, 250, 250) !important;
}