.follow-content-flex{
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 25px 0;
}
.follow-content-flex img{
    width: 40px;
    height: 40px;
    border-radius: 2px;
    object-fit: cover;
    margin-right: 13px;
}
.follow-content-flex span{
    font-weight: 500;
}
.follow-btn{
    background: #0095F6;
    color: white;
    padding: 3px 10px;
    border-radius: 5px;
    cursor: pointer;
    font-size: 14px;
    display: flex;
    align-items: center;
    justify-content: center;
}
.follow-btn-2{
    background: #0095F6;
    color: white;
    padding: 3px 10px;
    border-radius: 5px;
    cursor: pointer;
    font-size: 14px;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 55px;
    height: 26px;
}
.follow-btn-2 span{
    width: 15px !important; 
    height: 15px !important;
}
