.error-page{
    width: 100%;
    height: 100vh;
}
.error-page h1{
   font-size: 50px;
   position: absolute;
   top: 50%;
   left: 50%;
   transform: translate(-50%, -50%);
   color: aqua;
}