.select-cover-photo{
    width: 100%;
    height: auto; 
    display: flex;
    justify-content: center;
}
.select-cover-photo-box{
    width: 40%;
    height: auto;
    border: 1px solid #E4E4E4;
    margin-top: 90px;
    border-radius: 3px;
    padding: 20px;
}
.select-cover-photo-img-box{
    width: 100%;
    height: 100px;
    text-align: center;
}
.select-cover-photo-img{
    width: 100px;
    height: 100px;
    border-radius: 50%;
    object-fit: cover;
}
.select-cover-photo-blank{
    width: 100px;
    height: 100px;
    border-radius: 50%;
    background: #C4C4C4;
}
.select-cover-photo-box h4{
    text-align: center;
    color: #1B78CD;
    font-size: 18px;
    font-weight: 400;
    margin-top: 15px;
    margin-bottom: 47px;
    cursor: pointer;
}
.select-cover-photo-camera-sub{
    width: 65%;
    padding: 7px 10px;
    background: #D9E6FF;
    border-radius: 3px;
    display: flex;
    align-items: center;
}
.select-cover-photo-camera{
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 25px;
    margin-bottom: 5px;
}
.select-cover-photo-continue{
  display: flex;
  justify-content: center;
  margin-top: 30px;
}
.select-cover-photo-continue-sub{
    width: 65%;
    height: 42px;
    background: #2DC0DA;
    border-radius: 8px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
}
.select-cover-photo-continue-sub h3{
    color: white;
    font-weight: 400;
    font-size: 19px;
    margin: 0;
    letter-spacing: 2px;
}
.camView{
  display: flex;
  justify-content: center;
}
video{
    width: 100%;
    height: 100vh;
}
.captureImg{
    margin-top: 20px;
    width: 60%;
    height: 87vh;
    object-fit: cover;
}
.captureLogo{
    position: absolute;
    top: 90%;
    left: 50%;
    transform: translate(-50%, -50%);
}
.captureLogo svg{
    font-size: 50px;
    margin-top: 8px;
    cursor: pointer;
    color: #D2E3FE;
}
.cap-main{
    background: linear-gradient(to right, rgba(161, 196, 253, 0.5), rgba(194, 233, 251, 0.5));
    width: 100%;
    height: 100vh;
}
.cap-main-flex{
    display: flex;
    justify-content: center;
}